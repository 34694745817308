.Dashboard {
    height: 100%;
    width: 100%;
    display: flex;
    opacity: 0;
    overflow: hidden;

    transition: opacity 0.3s ease;

    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #051923;
}
.Dashboard.true {
    opacity: 1;
}
.Dashboard > .darken {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.3s ease;
}
.Dashboard > .darken.true {
    opacity: 1;
    pointer-events: all;
}
.Dashboard > .body {
    width: calc(400px + 40vw);
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.Dashboard > .body > .inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.Dashboard > .body > .logo {
    width: 35vh;
    margin: 4vh;
}
.Dashboard > .body > h3 {
    color: #c0c0c0;
}

.Dashboard > .body > .sites {
    width: 100%;
    margin-top: 50px;
    padding-bottom: calc(50px + 5vh);
    height: 100vh;
    overflow-y: scroll;
}
.Dashboard > .body > .sites > .siteCont {
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #202a30;
    position: relative;
}
.Dashboard > .body > .sites > .siteCont > .site {
    transition: background-color 0.3s ease;
    background-color: transparent;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30px;
    color: white;
    font-size: 20px;
}
.Dashboard > .body > .sites > .siteCont > .site:hover {
    background-color: #053147;
}
.Dashboard > .body > .sites > .siteCont > img {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    height: 40px;
    opacity: 0.1;
    transition: opacity 0.3s ease;
    pointer-events: none;
}
.Dashboard > .body > .sites > .siteCont:hover > img {
    opacity: 1;
}

.Dashboard > .profileBtn {
    position: absolute;
    height: 5vh;
    width: 10vh;
    top: 5vh;
    right: 2vh;
    cursor: pointer;
}

.EditSite * {
    font-family: 'Ubuntu Mono', monospace;
}
.EditSite {
    height: 88vh;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #051923;
    transition: transform 0.3s ease;
    padding-top: 5vh;

}
.EditSite.open {
    transform: translateY(0);
}
.EditSite.closed {
    transform: translateY(100%);
}

.EditSite > .body {
    width: calc(400px + 40vw);
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.EditSite > .body > .leftArrow {
    position: absolute;
    left: -10vh;
    top: 4vh;
    transform: translateY(-50%) rotate(180deg) scale(0.6);
    height: 8vh;
    cursor: pointer;
    opacity: 0;
    pointer-events: none;

    transition: opacity 0.3s ease;
}
.EditSite > .body > .leftArrow.true {
    opacity: 1;
    pointer-events: all;
}
.EditSite.closed > .body > .leftArrow {
    opacity: 0;
    pointer-events: none;
}
.EditSite > .body > .row {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3vh;
}
.EditSite > .body > .row > .trash {
    position: absolute;
    height: 4vh;
    width: 4vh;
    border-radius: 50%;
    border: 0.2vh solid #7D4747;
    opacity: 1;
    transition: opacity 0.3s ease;
    cursor: pointer;
    right: 17vh;
}
.EditSite > .body > .row > .trash.true {
    opacity: 0;
}

.EditSite > .body > .row.full .LockedInput {
    width: 100%;
}
.EditSite > .body > .row > span {
    width: 20%;
    color: white;
    font-size: 3vh;
    padding-left: 2vh;
}
.EditSite .LockedInput {
    background-color: #053147;
    position: relative;
    border-radius: 10px;
    height: 8vh;
    width: 80%;
}
.EditSite .LockedInput > .eye {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 6vh;
    height: 2vh;
}
.EditSite .LockedInput > .copy {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 2vh;
    height: 2.5vh;
}
.EditSite .LockedInput > .wand {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 11vh;
    height: 2.5vh;
    transition: opacity 0.3s ease;
}
.EditSite .LockedInput > .wand.visible {
    opacity: 1;
    pointer-events: all;
}
.EditSite .LockedInput > .wand.hidden {
    opacity: 0;
    pointer-events: none;
}

.EditSite .LockedInput > .placeholder {
    position: absolute;
    top: 50%;
    left: 2vh;
    transform: translateY(-50%);
    font-size: 2.5vh;
    pointer-events: none;
}
.EditSite .LockedInput > input {
    background-color: transparent;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    font-size: 2.5vh;
    color: white;
    padding-left: 2vh;

    transition: color 0.3s ease;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(-360deg);
    }
}


.SideBar {
    height: 100vh;
    width: 500px;
    min-width: 33vw;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #232323;
    overflow-y: scroll;
    z-index: 3;
    padding: 0 1vh;

    transition: transform 0.3s ease;
    transform: translateX(100%);
}
.SideBar.true {
    transform: translateX(0);
}
.SideBar > img.closeSideBar {
    position: absolute;
    top: 20px;
    left: 20px;
    height: 3vh;
    width: 3vh;
    cursor: pointer;
}
.SideBar > .profileImage {
    height: 10vh;
    width: 100%;
    position: relative;
    margin-top: calc(30px + 3vh);
    margin-bottom: 3vh;
}
.SideBar > .profileImage > img,
.SideBar > .profileImage > h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5vh;
}
.SideBar > .profileImage > img {
    height: 100%;
}
.SideBar > .profileImage > h2 {
    margin-top: 2vh;
    color: #8FBC9D;
}
.SideBar > h4 {
    color: #6C6C6C;
    font-size: 1.5vh;
    margin-bottom: 2vh;
    width: 100%;
    text-align: center;
}
.SideBar > .logoutBtn {
    background-color: #D9D9D9;
    color: black;
    padding: 2vh 0;
    font-size: 2vh;
    border-radius: 2vh;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 4vh;
    width: calc(10% + 100px);
    text-align: center;
}

.export {
    margin: 3vh 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.export > a {
    width: 20vh;
    height: 5vh;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-right: 2vh;
    text-decoration: none;
    font-weight: bold;
}
.export > a > img {
    height: 2vh;
    margin-right: 10px;
}

.SideBar > .inputSection {
    width: 100%;
}
.SideBar > .inputSection > span {
    width: 100%;
    text-align: left;
    color: rgb(169, 169, 169);
    padding-left: 5px;
    margin-bottom: 0.2vh;
    font-size: 1.8vh;
}
.SideBar > .inputSection > input {
    width: 100%;
    height: 6vh;
    outline: none;
    border: none;
    color: #ADADAD;
    background-color: #313131;
    border-radius: 1vh;
    padding-left: 10px;
    font-size: 2vh;
    margin-bottom: 1vh;
}
.SideBar > .inputSection > input::placeholder {
    color: #606060;
}
.SideBar > .inputSection > .saveBtn {
    background-color: #1E70BC;
    border-radius: 1vh;
    cursor: pointer;
    width: calc(10% + 50px);
    height: 0vh;
    margin-left: auto;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 0.3s ease;
    color: white;
}
.SideBar > .inputSection > .saveBtn.true {
    height: 4vh;
    margin-bottom: 1vh;
}

.SideBar > .inputSection > .underEmail {
    font-size: 1.5vh;
    width: 100%;
    padding: 1vh;
    color: #898989;
    margin-top: -2vh;
    
}
.SideBar > .inputSection > .underEmail.verified {
    color: #40714F;
}
.SideBar > .inputSection > .underEmail > span.resendEmail {
    color: #1E70BC;
    text-decoration: underline;
    cursor: pointer;
}
@media screen and (max-width: 1000px) {
    .EditSite > .body > .leftArrow {
        left: 0vh;
        top: -4vh;
    }
}

@media screen and (max-width: 700px) {
    .Dashboard > .body {
        width: 80vw;
    }
    .EditSite > .body {
        width: 80vw;
    }

    .EditSite > .body > .row {
        margin-bottom: 8vh;
    }
    .EditSite > .body > .row > span {
        position: absolute;
        left: 1vh;
        top: -4vh;
    }

    .EditSite .LockedInput {
        width: 100%;
    }

    .EditSite > .body > .leftArrow {
        transform: translateY(65vh) rotate(180deg) scale(0.6);
        z-index: 1;
    }
    .Dashboard > .body > .logo {
        margin-top: 9vh;
    }
    .Dashboard > .profileBtn {
        top: 3vh;
    }
}

@media screen and (max-width: 500px) {
    .SideBar {
        width: 100vw;
    }
}

@media screen and (max-width: 400px) {
    .Dashboard > .body > .sites {
        margin-top: 8vh;
    }
}