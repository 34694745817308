@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap'); /* font-family: 'IBM Plex Mono', monospace; */
@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono&display=swap'); /* font-family: 'Ubuntu Mono', monospace; */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'IBM Plex Mono', monospace;
}

body,
html,
#root,
.App {
    /* height: 100vh;
    width: 100vw;
    overflow: hidden; */
}

.hr {
    height: 0.1vh;
    background-color: #75B2D6;
}

.Loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    color: white;
    font-size: 2vh;

    transition: opacity 0.3s ease;

    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #051923;
}
.Loading > img {
    height: 2vh;
    width: 2vh;
    margin-right: 20px;
    animation: spin 1s linear infinite;
}
.Loading.fadeOut {
    opacity: 0;
}

.Input {
    position: relative;
    height: 9vh;
    background-color: #192A32;
    margin-bottom: 2vh;
    border-radius: 2vh;
}
.Input.indexLogin {
    width: calc(100px + 40%);
}
.Input > .placeholder {
    position: absolute;
    top: 50%;
    left: 2vh;
    transform: translateY(-50%);
    font-size: 2.5vh;
    pointer-events: none;
}
.Input > .eye {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5vh;
    height: 1.5vh;
}
.Input > input {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 2.5vh;
    padding: 2vh;
    color: white;
}

.btn {
    padding: 1vh 2vh;
    font-size: 2vh;
    border-radius: 1.5vh;
    cursor: pointer;
}

.SearchInput {
    position: relative;
    height: 5vh;
    width: 33%;
    background-color: transparent;
    margin-bottom: 2vh;
    border-bottom: 1px solid #75B2D6;
}
.SearchInput > .searchIcon {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    height: 2vh;
    margin-top: 0.2vh;
}
.SearchInput > input {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 2.5vh;
    padding-left: 3vh;
    color: white;
}
.SearchInput > .placeholder {
    position: absolute;
    top: 50%;
    left: 3vh;
    transform: translateY(-50%);
    font-size: 2.5vh;
    pointer-events: none;
}

.hollowBtn {
    width: 15vh;
    height: 4vh;
    cursor: pointer;
    color: white;
    border-radius: 1000px;
    border: 0.2vh solid #75B2D6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2px;
    font-size: 2vh;
}

.addSite {
    margin-top: auto;
    margin-bottom: 10px;
}

.alerts {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3vh;
    pointer-events: none;
}
.alerts > .alert {
    background-color: #286243;
    color: #88E8B4;
    border-radius: 10000vh;
    height: fit-content;
    min-height: 5vh;
    width: fit-content;
    max-width: 95vw;
    display: flex;
    align-items: center;
    justify-self: start;
    padding: 0 1vh;
    font-size: 1.7vh;
    animation: slideDown cubic-bezier(.34,1.41,.67,.99) forwards 0.3s;
    pointer-events: all;
    text-align: center;
}
@keyframes slideDown {
    0% {
        transform: translateY(-10vh);
    }
    100% {
        transform: translateY(0vh);
    }
}
.alerts > .alert.animate {
    animation: slideUp cubic-bezier(.34,1.41,.67,.99) forwards 0.3s;
}
@keyframes slideUp {
    0% {
        transform: translateY(0vh);
    }
    100% {
        transform: translateY(-10vh);
    }
}
.alerts > .alert > img {
    height: 3vh;
    width: 3vh;
    margin-right: 1vh;
    cursor: pointer;
}
.alerts > .alert.false {
    background-color: #622828;
    color: #E88888;
    
}


@media screen and (max-width: 1160px) {
    .Input.indexLogin {
        width: 95%;
    }
}

@media screen and (max-width: 700px) {
    .SearchInput {
        width: 50%;
    }
    .SearchInput > input {
        font-size: 2vh;
    }
    .SearchInput > .placeholder {
        font-size: 2vh;
    }
}

@media screen and (max-width: 400px) {
    .SearchInput {
        width: 100%;
    }
    .addSite {
        position: absolute;
        left: 50%;
        bottom: -6vh;
        transform: translateX(-50%);
    }
}

/* SCROLLBAR */
*::-webkit-scrollbar {
    width: 0.4vh; 
    height: 1.2vh;
  }
  *::-webkit-scrollbar-track {
    background-color: transparent; 
    cursor: pointer;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #888; 
    border-radius: 10px;
    cursor: pointer; 
  }
  *::-webkit-scrollbar-thumb:active {
    cursor: pointer;
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
  }