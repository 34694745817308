
.Index, .ForgotPassword {
    display: flex;

    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: #051923;
}

.leftSide {
    width: calc(55vw - 2px);
    height: 100%;
}
.rightSide {
    width: 45vw;
    height: 100vh;
    overflow: hidden;
}
.rightSide > .slide {
    width: 200%;
    height: 100%;
    transform: translateX(0);
    display: flex;
    color: white;


    transition: transform 0.3s ease;
}
.rightSide > .slide.false {
    transform: translateX(-50%);
}

.title {
    margin-top: 40vh;
    margin-left: 8vw;
    margin-right: 2vh;
}
.title > img {
    width: calc(100px + 20vw)
}
.title > h2 {
    color: #A1A1A1;
    font-size: calc(5px + 2vw);
}


.Login {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15vh;
}
.Login > .hr {
    margin: 4vh 0;
}
.Login > h1 {
    font-size: 3vh;
}

@media screen and (max-width: 850px) {
    .Index {
        flex-direction: column;
        align-items: center;
    }
    .Index > img {
        display: none;
    }
    .leftSide {
        width: 100%;
        height: 10vh;
    }
    .title {
        margin-top: 2vh;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .Login {
        padding-top: 4vh;
    }
}

@media screen and (max-width: 700px) {
    .rightSide {
        width: 70vw;
    }
}
@media screen and (max-width: 500px) {
    .rightSide {
        width: 90vw;
    }
}